import type { JSONSchemaType, ValidateFunction, ErrorObject } from 'ajv';
import Ajv from 'ajv';
// import betterAjvErrors from 'better-ajv-errors';
import type {
  AnalysisFormat,
  AnalysisFormatNoMeta,
  LabelFormat,
  LayoutFormat,
} from './analysis-formats';
import {
  FontStyleList,
  FontWeightList,
  FONT_SIZE_MAX,
  FONT_SIZE_MIN,
  LineNameList,
} from './analysis-formats';

// The AJV schemas to verify the analysis format

// Transform object and its children into Required
// This avoids the nullable problem with AJV
type DeepRequired<T> = {
  [P in keyof Required<T>]: DeepRequired<Required<T>[P]>
};

const labelSchema: JSONSchemaType<Required<LabelFormat>> = {
  type: 'object',
  properties: {
    start: { type: ['number', 'string'] },
    type: { type: 'string' },
    tag: { type: 'string' },
    comment: { type: 'string' },
    duration: { type: ['number', 'string'] },
    ioi: { type: ['number', 'string'] },
    staff: { type: 'string', format: 'nonzero-uint' },
    end: { type: ['number', 'string'] },
    line: { type: 'string', enum: LineNameList },
    layers: { type: 'array', items: { type: 'string' } },
  },
  required: [],
};

// Deep required is necessary because JVM does not handle optional values correctly
const layoutSchema: JSONSchemaType<DeepRequired<LayoutFormat>> = {
  type: 'object',
  properties: {
    filter: {
      type: 'object',
      // At least one filter must be active
      properties: {
        tag: { type: 'string', minLength: 1 },
        type: { type: 'string', minLength: 1 },
        layers: { type: 'array', items: { type: 'string' }, minItems: 1 },
      },
      required: [],
      minProperties: 1,
    },
    style: {
      type: 'object',
      properties: {
        line: { type: 'string', enum: LineNameList },
        color: { type: 'string', format: 'color' },
        type: { type: 'string' },
        symbol: { type: 'string' },
      },
      required: [],
    },
    textStyle: {
      type: 'object',
      properties: {
        fontStyle: { type: 'string', enum: FontStyleList },
        fontWeight: { type: 'string', enum: FontWeightList },
        fontSize: { type: 'integer', minimum: FONT_SIZE_MIN, maximum: FONT_SIZE_MAX },
      },
      required: [],
    },
  },
  required: ['filter'],
};

const analysisSchema: JSONSchemaType<DeepRequired<AnalysisFormatNoMeta>> = {
  type: 'object',
  properties: {
    labels: { type: 'array', items: labelSchema },
    meta: {
      type: 'object',
      properties: {
        layout: { type: 'array', items: layoutSchema },
        creationButtons: { type: 'array', items: labelSchema },
      },
      required: [],
    },
  },
  required: ['labels'],
};

const ajv = new Ajv({ coerceTypes: true });

ajv.addFormat('color', {
  type: 'string',
  validate: (s: string) => !!s.match(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/) || s === 'auto' || true,
});
ajv.addFormat('nonzero-uint', {
  type: 'string',
  validate: (s: string) => !!s.match(/^[1-9][0-9]*$/),
});

const analysisTransformer: ValidateFunction<AnalysisFormat> = ajv.compile(analysisSchema);

export function analysisLoggerTransformer(format: unknown): format is AnalysisFormat {
  if (analysisTransformer(format)) return true;

  // eslint-disable-next-line no-console
  console.error(analysisTransformer.errors?.map((e: unknown) => {
    const error = e as ErrorObject;
    return `${error.instancePath}: ${error.message}`;
  }).join('\n'));
  // console.error(betterAjvErrors(analysisSchema, format, analysisTransformer.errors));
  throw new Error('Corrupted Analysis');
}
