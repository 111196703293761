import type {
  UnitPair,
  UnitTable,
  Onset,
  UnitValue,
} from './Unit';
import type Unit from './Unit';

export type SyncPoint = UnitPair<number, number>;
export type SyncIndex = UnitValue<number, 'SyncIndex'>;

export type IndexMap = Map<SyncIndex, SyncIndex | null>;

type ReadonlyTable = Readonly<UnitTable>;

function clonePoint(syncPoint: SyncPoint): SyncPoint {
  return syncPoint.slice(0, 3) as [number, number, string] as SyncPoint;
}

function pointCmp(a: SyncPoint, b: SyncPoint): number {
  return a[0] - b[0] || a[1] - b[1];
}

function sortTable(table: SyncPoint[]) {
  table.sort(pointCmp);
}

// TODO: make less dependant on reference
function getIndexUpdateMap(oldTable: SyncPoint[], newTable: SyncPoint[]): IndexMap {
  const newIdMap = new Map(newTable.map((point, index) => [point, index as SyncIndex]));

  const idRecord: IndexMap = new Map();

  oldTable.forEach((point, oldIndex) => {
    const newIndex = newIdMap.get(point) ?? null;
    if (oldIndex !== newIndex) idRecord.set(oldIndex as SyncIndex, newIndex);
    newIdMap.delete(point);
  });

  let newIndices = oldTable.length;

  newIdMap.forEach((newIndex) => {
    idRecord.set(newIndices as SyncIndex, newIndex);
    newIndices += 1;
  });

  return idRecord;
}

/**
 * An editable variant of unit
 *
 * Used by SynchronizationActions to change the current unit
 *
 * All functions are O(n log(n)) because of the checks required
 * Since updating unit itself should have little DOM cost per point, this is acceptable
 */
export default class EditableUnit {
  unit: Unit<number>;

  public constructor(unit: Unit<number>, indexToEdit: number) {
    this.unit = unit;
    this.index = indexToEdit;
    // Backup the table
    this.originalTable = [...unit.tables[indexToEdit]];
  }

  private originalTable: ReadonlyTable;

  index: number;

  reInitTableCounter = 0;

  private get points(): SyncPoint[] {
    return this.unit.tables[this.index];
  }

  private checkUnit(): void {
    // TODO: remove check once EditableUnit is tested
    const invalidIndex = this.points.findIndex((p, index) => {
      const np = this.points?.[index + 1] ?? [Infinity, Infinity];
      return p[0] > np[0]; // || p[1] > np[1];
    });
    if (invalidIndex >= 0) {
      // eslint-disable-next-line no-console
      console.error('Corrupted at', invalidIndex, this.points[invalidIndex], this.points[invalidIndex + 1]);
    }
  }

  private checkTable(table: ReadonlyTable): boolean {
    if (table.length < 2) return false;

    const invalidIndex = table.findIndex((p, index) => {
      const np = table?.[index + 1] ?? [Infinity, Infinity];
      return !Number.isFinite(p[0])
        || !Number.isFinite(p[1])
        || p[0] > np[0];
      // || p[1] > np[1];
    });
    return invalidIndex === -1;
  }

  overrideOriginalTable(table: UnitTable): void {
    this.originalTable = [...table];
  }

  setTable(table: ReadonlyTable): boolean {
    // Check that the table is valid
    if (!this.checkTable(table)) return false;

    // Set the table values
    this.points.splice(0, this.points.length, ...table);

    // Check all was well
    this.checkUnit();

    return true;
  }

  resetTable(): void {
    this.reInitTableCounter += 1;
    this.setTable(this.originalTable);
  }

  getTable(): Readonly<SyncPoint[]> {
    return this.points;
  }

  getSyncPointFromMusicalTime(onset: Onset, repeat: string | undefined = undefined): SyncPoint {
    const source = this.unit.fromOnsetToPartial(onset, this.index, repeat);
    const target = this.unit.fromOnsetToPartial(onset, this.index + 1, repeat);

    return [source, target];
  }

  getSyncPointFromTarget(unitTarget: number): SyncPoint {
    const target = this.unit.fromTargetToPartial(unitTarget, this.index + 1);
    const source = this.unit.fromTargetToPartial(unitTarget, this.index);

    return [source, target];
  }

  createSyncPoint(p: SyncPoint): IndexMap {
    const newPoints = [...this.points, clonePoint(p)];
    sortTable(newPoints);
    const map = getIndexUpdateMap(this.points, newPoints);
    if (map.get((newPoints.length - 1) as SyncIndex) === null) throw new Error('Unexpected Error');
    if (!this.setTable(newPoints)) throw new Error('Could not create point');
    return map;
  }

  deleteSyncPoint(p: SyncIndex): IndexMap {
    const newPoints = [...this.points];
    newPoints.splice(p, 1);
    const map = getIndexUpdateMap(this.points, newPoints);
    if (!this.setTable(newPoints)) throw new Error('Could not delete point');
    return map;
  }

  setSyncPoint(index: SyncIndex, p: SyncPoint): IndexMap {
    const newPoints = [...this.points];
    newPoints[index] = clonePoint(p);
    sortTable(newPoints);
    const map = getIndexUpdateMap(this.points, newPoints);
    const newIndex = map.get(this.points.length as SyncIndex);

    if (newIndex === undefined) throw new Error('Unexpected Error');
    if (map.get(index) !== null) throw new Error('Unexpected Error');
    map.set(index, newIndex);
    map.delete(this.points.length as SyncIndex);

    if (!this.setTable(newPoints)) throw new Error('Could not set point');
    return map;
  }
}
