import type {
  UnitTable, Pixel, Second, EditableUnit, Onset,
} from '@/data';
import {
  Unit, PixelUnitWithSeconds,
} from '@/data';
import PixelUtils from '@/data/PixelUtils';

/**
 * A row of any unit conversion file, like:
 *
 * UnitCouple<'x' | 'onset'> <==> { x: number; onset: number }
 */
 type UnitCouple<Key extends string> = {
   [P in Key]: number;
 };

 type UnitCoupleWithRepeat<Key extends string> = UnitCouple<Key> & {
   repeat?: string;
 };

/**
 * A sorted array of UnitCouple as found in synch.json files
 * */
export type UnitCoupleFormat<Key extends string> = UnitCoupleWithRepeat<Key>[];

/**
 * A staff or line, as found in positions.json
 *
 * Currently unused
 */
export interface StaffFormat {
  bottom: number;
  top: number;
}

/**
 * The positions.json file format for spectrograms
 */
export interface AudioImageSyncFormat {
  'date-x': UnitCoupleFormat<'date' | 'x'>;
  // Move to unit format?? not conversion
  'staffs': StaffFormat[];
}

/**
 * The synchro.json file for audio files
 */
export type AudioSyncFormat = UnitCoupleFormat<'date' | 'onset'>;

/**
 * The positions.json file for musical scores
 */
export interface ImageSyncFormat {
  bars: number[],
  'onset-x': UnitCoupleFormat<'onset' | 'x'>;
  staffs: StaffFormat[],
}

/**
 * A row of a multidimensional musical score
 */

export interface Image3DRowFormat {
  staves?: StaffFormat[];
  y: number;
  height: number;
  'start-x': number;
  'end-x': number;
  'onset-x': UnitCoupleFormat<'onset' | 'x'>;
}

/**
 * A page of a multidimensional musical score
 */

export interface Image3DPageFormat {
  image: string;
  rows: Image3DRowFormat[];
}

/**
 * The positions.json file for multidimensional musical scores
 */
export interface Image3DSyncFormat {
  pages: Image3DPageFormat[];
}

export default class UnitParser extends Unit<number> {
  /**
   * Converts the standard synchronization format to a UnitTable
   * @param format the parsed json object in the form of ({x: num; y:num}[])
   * @param from the key of the onset part of the object 'x'
   * @param to the key of the target unit part of the object
   * @returns a unit table
   */
  private static parseUnitTableFormat<Key extends string>(
    format: UnitCoupleFormat<Key>,
    from: Key,
    to: Key,
  ): UnitTable {
    const res: UnitTable = [];
    let fromLast: number;
    let toLast: number;

    // Consider only sorted pairs
    format.forEach((f) => {
      if (fromLast === undefined
        || (fromLast <= f[from] && toLast <= f[to])
        || f.repeat) {
        if (f.repeat) {
          res.push([f[from], f[to], f.repeat]);
        } else {
          res.push([f[from], f[to]]);
          fromLast = f[from];
          toLast = f[to];
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Not sorted: ${from}/${to} (${fromLast}/${toLast}) ${f[from]}/${f[to]}`);
      }
    });
    // Check length
    if (res.length < 2) throw new Error('Unit table length < 2');

    return res;
  }

  /**
   * Create a pixel unit conversion object from the audio and audio image sync files
   * @param audioSync the audio synchro.json file
   * @param audioPos  the audio image positions.json
   * @returns a pixel-onset conversion object
   */
  static createAudioImageUnit(
    secondUnit: Unit<Second>,
    audioPos: AudioImageSyncFormat,
  ): PixelUnitWithSeconds {
    // Build spectrogram unit table
    const pixelTable = this.parseAudioImageTableFormat(audioPos['date-x']);
    // Combine both
    return PixelUnitWithSeconds.fromAudioUnit(secondUnit, pixelTable);
  }

  /**
   * Create an audio timestamp unit conversion object from the audio sync files
   * @param audioSync the audio synchro.json file
   * @returns a second-onset conversion object
   */
  static createAudioUnit(audioSync: AudioSyncFormat): Unit<Second> {
    return new Unit([
      this.parseAudioTableFormat(audioSync),
    ]);
  }

  /**
   * Create an image pixel unit conversion object from the image sync files
   * @param imagePos the image positions.json file
   * @returns a pixel-onset conversion object
   */
  static createImageUnit(imagePos: ImageSyncFormat): Unit<Pixel> {
    return new Unit([
      this.parseImageTableFormat(imagePos['onset-x']),
    ]);
  }

  static createImage3DUnit(imagePos: Image3DSyncFormat): Unit<Pixel> {
    const onsets: UnitCoupleFormat<'x' | 'onset'> = [];
    imagePos.pages.forEach((page, pageIndex) => {
      page.rows.forEach((row, rowIndex) => {
        row['onset-x'].forEach((onset) => {
          onsets.push({
            onset: onset.onset,
            x: PixelUtils.mux({
              page: pageIndex,
              row: rowIndex,
              x: onset.x as Pixel,
            }),
          });
        });
      });
    });
    return new Unit([
      this.parseImage3DTableFormat(onsets),
    ]);
  }

  private static parseAudioImageTableFormat(audioPos: UnitCoupleFormat<'date' | 'x'>): UnitTable<Second, Pixel> {
    return UnitParser.parseUnitTableFormat(audioPos, 'date', 'x') as UnitTable<Second, Pixel>;
  }

  static parseAudioTableFormat(audioSync: UnitCoupleFormat<'onset' | 'date'>): UnitTable<Onset, Second> {
    return UnitParser.parseUnitTableFormat(audioSync, 'onset', 'date') as UnitTable<Onset, Second>;
  }

  static parseImageTableFormat(imagePos: UnitCoupleFormat<'onset' | 'x'>): UnitTable<Onset, Pixel> {
    return UnitParser.parseUnitTableFormat(imagePos, 'onset', 'x') as UnitTable<Onset, Pixel>;
  }

  private static parseImage3DTableFormat(imagePos: UnitCoupleFormat<'onset' | 'x'>): UnitTable<Onset, Pixel> {
    const unitTable = UnitParser.parseUnitTableFormat(imagePos, 'onset', 'x') as UnitTable<Onset, Pixel>;
    return unitTable;
  }

  private static exportFormat<
    Source extends string,
    Target extends string
  >(
    table: Readonly<UnitTable>,
    sourceKey: Source,
    targetKey: Target,
  ): UnitCoupleFormat<Source | Target> {
    // Map sync point tuple to unit couple object
    return table.map(([source, target]) => ({
      [sourceKey]: source,
      [targetKey]: target,
    } as UnitCouple<Source | Target>));
  }

  static exportAudioFormat(unit: EditableUnit): UnitCoupleFormat<'onset' | 'date'> {
    return UnitParser.exportFormat(unit.getTable(), 'onset', 'date');
  }
}
