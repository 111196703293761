import CorpusData from '@/data/CorpusData';

type Locale = string;

type CorpusTitleKey = `title:${Locale}`;

type CorpusTitleMap = Record<CorpusTitleKey, string>;

type CorpusTextKey = `text:${Locale}`;

type CorpusTextMap = Record<CorpusTextKey, string>;

type CorpusMottoKey = `motto:${Locale}`;

type CorpusMottoMap = Record<CorpusMottoKey, string>;

type CorpusRefKey = `ref:${string}`;

type CorpusRefMap = Record<CorpusRefKey, string>;

export interface CorpusDataFormat
  extends CorpusTitleMap, CorpusTextMap, CorpusMottoMap, CorpusRefMap {
  id: string,
  path: string,
  title: string,
  shorttitle?: string,
  contributors?: Record<string, string>,
  year?: string,
  genre?: string,
  text?: string,
  availability?: string,
  image?: string,
  imageCard?: string,
  'quality:corpus'?: number,
  showcase?: Array<string>,
  opus?: string,
  motto?: string,
  ref?: string,
  columns?: string,
}

function checkString(val: unknown): string {
  if (typeof val === 'number') return val.toString();
  if (typeof val !== 'string') {
    throw new Error(`Corrupted Corpus: ${val} is not a string`);
  }
  return val;
}

export default class CorpusParser extends CorpusData {
  static fromFormat(
    format: CorpusDataFormat,
  ): CorpusData {
    const id = checkString(format.id);
    const path = checkString(format.path);

    const title = checkString(format.title || '');
    const shorttitle = checkString(format.shorttitle || '');
    const text = checkString(format.text || '');
    const motto = checkString(format.motto || '');
    const columns = checkString(format.columns || '');

    const localizedShortTitles: Record<Locale, string> = {};
    const localizedTitles: Record<Locale, string> = {};
    const localizedTexts: Record<Locale, string> = {};
    const localizedMottos: Record<Locale, string> = {};
    const localizedAvailabilities: Record<Locale, string> = {};
    const refs: Record<string, string> = {};

    Object.keys(format).forEach((k) => {
      if (k.substring(0, 3) === 'ref') {
        refs[k.substring(4)] = checkString((format as unknown as Record<string, string>)[k]);
      }
      const splitKey = k.split(':');
      if (splitKey.length === 2) {
        if (splitKey[0] === 'shorttitle') {
          const localeShortTitle = checkString((format as unknown as Record<string, string>)[k]);
          localizedShortTitles[splitKey[1]] = localeShortTitle;
        }
        if (splitKey[0] === 'title') {
          const localeTitle = checkString((format as unknown as Record<string, string>)[k]);
          localizedTitles[splitKey[1]] = localeTitle;
        }
        if (splitKey[0] === 'text') {
          const localeText = checkString((format as unknown as Record<string, string>)[k]);
          localizedTexts[splitKey[1]] = localeText;
        }
        if (splitKey[0] === 'motto') {
          const localeMotto = checkString((format as unknown as Record<string, string>)[k]);
          localizedMottos[splitKey[1]] = localeMotto;
        }
        if (splitKey[0] === 'availability') {
          const localeAvailability = checkString((format as unknown as Record<string, string>)[k]);
          localizedAvailabilities[splitKey[1]] = localeAvailability;
        }
      }
    });

    const contributors = format.contributors || {};

    const year = checkString(format.year || '');
    const genre = checkString(format.genre || '');
    const availability = checkString(format.availability || '');
    const quality = format['quality:corpus'] ?? 0;
    const image = checkString(format.image || '');
    const imageCard = checkString((format as unknown as Record<string, string>)['image:card'] || image);
    const showcase = format.showcase ? format.showcase[Math.round(Math.random() * (format.showcase.length - 1))] : '';

    const opus = checkString(format.opus || '');

    return new CorpusData({
      id,
      path,
      title,
      localizedTitles,
      shorttitle,
      localizedShortTitles,
      text,
      localizedTexts,
      refs,
      contributors,
      motto,
      localizedMottos,
      year,
      genre,
      availability,
      localizedAvailabilities,
      quality,
      image,
      imageCard,
      showcase,
      opus,
      columns,
    });
  }
}
