import type {
  FilterGroup,
  Label,
  StaffId,
  Duration,
  Onset,
  LabelTemplate,
  OnsetString,
} from '@/data';
import {
  defaultTypes,
  lineIds,
  Signature,
  STAFF_ID_OFFSET,
} from '@/data';
import type { LabelDuration, LabelNoId } from '@/data/Label';

import type { LabelFormat } from './analysis-formats';
import { LineNameList } from './analysis-formats';

export default class LabelParser {
  /**
   * Attempt to get the staff from a label format
   * @param format the label
   * @param filters the metadata on label placement
   * @returns the staff id
   */
  static getStaff(format: LabelFormat, filters: FilterGroup): StaffId {
    const {
      staff = null,
      line = null,
      type = '',
      tag = '',
      layers = [],
      duration = 0,
    } = format;

    // TODO: encapsulate the line/staff name/id conversion in own file

    // If staff is defined, convert to index
    // Actual staff may not exist
    if (staff !== null) return Number.parseInt(staff, 10) - 1 + STAFF_ID_OFFSET;

    // If line is defined, return the index of that line
    // line is garanteed to be a real line name
    if (line !== null) return LineNameList.indexOf(line);

    // Attempt to get the default line for the type and layers
    const lineId = filters.getDefaultLine(type, tag, layers)
        || defaultTypes.getDefaultLine(type, tag, layers);

    if (lineId !== null) return lineId;

    // If the label is a bar, return the bar line
    if (!duration) return lineIds.all;

    // If the label has a duration, return the first line as a last resort
    return lineIds.all;
  }

  /**
   * Get the staff format from the analysis label
   * Does not return any info if not necessary
   * @param label the label to get the format of
   * @returns an object containing all staff info to inline in the label format
   */
  static getStaffFormat(label: Label | LabelTemplate): Pick<LabelFormat, 'line' | 'staff'> {
    if (!label.wasStaffLoaded) return {};

    if (label.staffId === undefined) return {};

    if (label.staffId < STAFF_ID_OFFSET) return { line: LineNameList[label.staffId] };

    return { staff: `${label.staffId + 1 - STAFF_ID_OFFSET}` };
  }

  /**
   * Converts a label template format into a label template
   */
  public static fromTemplateFormat(label: LabelFormat, filters: FilterGroup): LabelTemplate {
    const {
      type = 'Pattern', tag = '', layers = ['no-layer'],
    } = label;
    const staffId = this.getStaff(label, filters);

    const durationType = (label.ioi) && +label.ioi !== 0 ? 'ioi' : 'duration';

    if (staffId === lineIds.all) {
      return {
        type, tag, layers, durationType,
      };
    }

    return {
      staffId, type, tag, layers, durationType,
    };
  }

  /**
   * Converts a label format into a label
   */
  public static fromFormat(
    label: LabelFormat,
    filters: FilterGroup,
    signature: Signature = new Signature(),
  ): LabelNoId {
    const {
      start: rawStart = 0, duration: rawDuration = 0, ioi: rawIoi = 0, end: rawEnd = 0, type = 'Pattern', tag = '', comment = '', layers = ['no-layer'],
    } = label;
    const onset = LabelParser.parse(rawStart, signature) as Onset;
    const parsedDuration = LabelParser.parse(rawDuration, signature);
    const ioi = LabelParser.parse(rawIoi, signature);
    const end = LabelParser.parse(rawEnd, signature);
    const [duration, durationType] = LabelParser.getDuration(
      onset,
      parsedDuration,
      ioi,
      end,
    );
    return {
      duration,
      durationType,
      onset,
      staffId: this.getStaff(label, filters),
      wasStaffLoaded: !!(label.staff || label.line),
      type,
      tag,
      comment,
      layers,
      isResizable: filters.styleType(label.tag, label.type) !== 'mini',
      symbol: filters.symbol(label.tag),
    };
  }

  private static parse(value: string | number, signature: Signature): number {
    if (typeof value !== 'string' || !value.includes('m')) return +value;
    const valueWithoutM = value.replace('m', '') as OnsetString;
    if (value.indexOf('m') === 0) return signature.parseOnset(valueWithoutM);
    return signature.parseDuration(valueWithoutM);
  }

  private static getDuration(
    start: number,
    duration: number,
    ioi: number,
    end: number,
  ): [Duration, LabelDuration] {
    if (ioi > 0) return [ioi as Duration, 'ioi'];
    if (duration > 0) return [duration as Duration, 'duration'];
    const diff = end - start;
    if (diff > 0) return [diff as Duration, 'duration'];
    return [0 as Duration, 'point'];
  }

  /**
   * Serialize a label template
   */
  static exportTemplateFormat(l: LabelTemplate): LabelFormat {
    const durationNonZero = l?.duration || undefined;

    let layers: string[] | undefined;
    if (l.layers) {
      layers = l.layers.filter((layer) => layer !== 'no-layer');
    }
    return {
      type: l.type,
      tag: l.tag || undefined,
      layers: layers?.length ? layers : undefined,
      duration: l?.durationType === 'ioi' ? undefined : durationNonZero ?? undefined,
      ioi: l?.durationType === 'ioi' ? durationNonZero : undefined ?? undefined,
      ...LabelParser.getStaffFormat(l),
    };
  }

  /**
   * Serialize a label
   */
  static exportFormat(l: Label): LabelFormat {
    return {
      ...LabelParser.exportTemplateFormat(l),
      start: l?.onset || undefined,
      comment: l?.comment || undefined,
    };
  }
}
